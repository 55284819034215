import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useCart } from "react-use-cart";
import "./OffCanvas.css";

function OffCanvasExample({ name, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {
    isEmpty,
    items,
    updateItemQuantity,
    removeItem,
    totalUniqueItems,
    emptyCart,
  } = useCart();

  // Total Sale Price Calculation with reduce
  const cartTotal = items.reduce((prev,curr) =>prev + curr.sale_price * curr.quantity,0)

  return (
    <div className="off_canvas_parent">
      <Button variant="primary" onClick={handleShow} className="off_canvas_btn">
      <i class="fa-solid fa-basket-shopping"></i> <br/>
      <span>{totalUniqueItems} {name}</span> <br/>
      <span>${cartTotal}</span>
      </Button>
      <Offcanvas
        show={show}
        onHide={handleClose}
        {...props}
        style={{ marginTop: "80px" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h3>My Cart</h3>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="cart-dropdown">
            <ul className="custom-scrollbar">
              {/* ------------------------------ */}
              {items.map((cart) => (
                <div key={cart.id}>
                  <li>
                    <div className="media mb-4">
                      <img
                        className="img-fluid b-r-5 me-3 img-50 border rounded-pill"
                        src={cart.thumbnail}
                        alt=""
                      />
                      <div className="media-body">
                        <h6>
                          <a
                            className="text-decoration-none text-dark"
                            href="product.html"
                          >
                            {cart.title.length > 20
                              ? cart.title.slice(0, 19) + "..."
                              : cart.title}
                          </a>
                        </h6>
                        <div className="d-flex">
                          <div className="cart-quantity">
                            <span
                              onClick={() =>
                                updateItemQuantity(cart.id, cart.quantity - 1)
                              }
                              className="minus cart_minus"
                            >
                              <i className="fa fa-minus"></i>
                            </span>
                            <input
                              className="cart_input"
                              type="number"
                              name="qty"
                              value={cart.quantity}
                            />

                            <span
                              onClick={() =>
                                updateItemQuantity(cart.id, cart.quantity + 1)
                              }
                              className="plus cart_plus"
                            >
                              <i className="fa fa-plus"></i>
                            </span>
                          </div>
                          <h6 className="f-w-700 ms-auto">${cart.sale_price}</h6>
                        </div>
                      </div>
                      <h6
                        className="text-danger mx-2 remove_icon"
                        onClick={() => removeItem(cart.id)}
                      >
                        <i class="fa-solid fa-xmark"></i>
                      </h6>
                      <div className="close-circle">
                        <a href="/">
                          <i className="me-1" data-feather="edit"></i>
                        </a>
                        <a href="/">
                          <i data-feather="trash-2"></i>
                        </a>
                      </div>
                    </div>
                  </li>
                </div>
              ))}
              {/* //-------------------------------// */}

              {isEmpty ? (
                <h3 className="text-secondary ms-5 mt-5">
                  Empty Cart <i className="fa-brands fa-opencart"></i>
                </h3>
              ) : (
                <div className="d-flex mt-5">
                  <li className="order-total">
                    <h4 className="mb-0 f-w-700 text-secondary">
                      Total : <span>${cartTotal}</span>
                    </h4>
                    {/* <a
                      className="btn btn-primary view-checkout"
                      href="checkout.html"
                    >
                      Checkout
                    </a> */}
                  </li>
                  <li className="ms-auto">
                    <button className="btn text-danger border fw-bold" onClick={emptyCart}>
                      Clear Cart <i className="fa-regular fa-trash-can"></i>
                    </button>
                  </li>
                </div>
              )}
            </ul>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

const OffCanvas = () => {
  return (
    <>
      {["Items"].map((placement, idx) => (
        <OffCanvasExample key={idx} placement={"end"} name={placement} />
      ))}
    </>
  );
};

export default OffCanvas;
