import React, { useContext } from "react";
import { useCart } from "react-use-cart";
import { MyContext } from "../../App";
import MyCart from "../MyCart/MyCart";

const PageHeader = () => {
  // consume props with using context api
  const [isOpen, , handleOnChange, toggleSideBar] = useContext(MyContext);
  const {totalItems} = useCart();

  return (
    <div>
      {/* <!-- tap on top starts--> */}
      <div className="tap-top">
        <i data-feather="chevrons-up"></i>
      </div>
      {/* <!-- tap on tap ends--> */}
      <div className="page-wrapper compact-wrapper" id="">
        {/* <!-- Page Header Start--> */}
        <div className="page-body-wrapper">
          <div className={`page-header ${isOpen ? "" : "ms-0 w-100"}`}>
            <div className="header-wrapper row m-0">
              <div
                className="header-logo-wrapper col-auto p-0"
                onClick={toggleSideBar}
              >
                <div className="logo-wrapper">
                  <a href="index.html">
                    <img
                      className="img-fluid"
                      src="aseets/images/logo/logo.png"
                      alt=""
                    />
                  </a>
                </div>
                <div className="toggle-sidebar">
                  <div className="status_toggle sidebar-toggle d-flex">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <g>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M21.0003 6.6738C21.0003 8.7024 19.3551 10.3476 17.3265 10.3476C15.2979 10.3476 13.6536 8.7024 13.6536 6.6738C13.6536 4.6452 15.2979 3 17.3265 3C19.3551 3 21.0003 4.6452 21.0003 6.6738Z"
                            stroke="#130F26"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10.3467 6.6738C10.3467 8.7024 8.7024 10.3476 6.6729 10.3476C4.6452 10.3476 3 8.7024 3 6.6738C3 4.6452 4.6452 3 6.6729 3C8.7024 3 10.3467 4.6452 10.3467 6.6738Z"
                            stroke="#130F26"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M21.0003 17.2619C21.0003 19.2905 19.3551 20.9348 17.3265 20.9348C15.2979 20.9348 13.6536 19.2905 13.6536 17.2619C13.6536 15.2333 15.2979 13.5881 17.3265 13.5881C19.3551 13.5881 21.0003 15.2333 21.0003 17.2619Z"
                            stroke="#130F26"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10.3467 17.2619C10.3467 19.2905 8.7024 20.9348 6.6729 20.9348C4.6452 20.9348 3 19.2905 3 17.2619C3 15.2333 4.6452 13.5881 6.6729 13.5881C8.7024 13.5881 10.3467 15.2333 10.3467 17.2619Z"
                            stroke="#130F26"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="left-side-header col ps-0 d-none d-md-block">
                <div className="input-group">
                  <span className="input-group-text" id="basic-addon1">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <g>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11.2753 2.71436C16.0029 2.71436 19.8363 6.54674 19.8363 11.2753C19.8363 16.0039 16.0029 19.8363 11.2753 19.8363C6.54674 19.8363 2.71436 16.0039 2.71436 11.2753C2.71436 6.54674 6.54674 2.71436 11.2753 2.71436Z"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M19.8987 18.4878C20.6778 18.4878 21.3092 19.1202 21.3092 19.8983C21.3092 20.6783 20.6778 21.3097 19.8987 21.3097C19.1197 21.3097 18.4873 20.6783 18.4873 19.8983C18.4873 19.1202 19.1197 18.4878 19.8987 18.4878Z"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </span>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search here.."
                    aria-label="search"
                    aria-describedby="basic-addon1"
                    onChange={handleOnChange}
                  />
                </div>
              </div>
              <div className="nav-right col-10 col-sm-6 pull-right right-header p-0">
                <ul className="nav-menus">
                  <li className="d-md-none resp-serch-input">
                    <div className="resp-serch-box">
                      <i data-feather="search"></i>
                    </div>
                    <div className="form-group search-form">
                      <input type="text" placeholder="Search here..." />
                    </div>
                  </li>
                  <li className="cart-nav onhover-dropdown">
                    <div className="cart-box">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g>
                          <g>
                            <path
                              d="M5.52377 7C9.41427 5.74386 13.9724 5.45573 16 5.5C18.0276 5.54427 18.8831 6.2663 19.5 7.5C20.5 9.5 20.289 14.4881 18.5 16.0871C16.712 17.6861 9.33015 17.8381 6.87015 16.0871C4.27115 14.2361 5.629 9.192 5.544 5.743C5.595 3.813 3.5 3.5 3.5 3.5"
                              stroke="#130F26"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M13 10.5H15.773"
                              stroke="#130F26"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7.26399 20.1274C7.56399 20.1274 7.80799 20.3714 7.80799 20.6714C7.80799 20.9724 7.56399 21.2164 7.26399 21.2164C6.96299 21.2164 6.71899 20.9724 6.71899 20.6714C6.71899 20.3714 6.96299 20.1274 7.26399 20.1274Z"
                              fill="#130F26"
                              stroke="#130F26"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M17.5954 20.1274C17.8964 20.1274 18.1404 20.3714 18.1404 20.6714C18.1404 20.9724 17.8964 21.2164 17.5954 21.2164C17.2954 21.2164 17.0514 20.9724 17.0514 20.6714C17.0514 20.3714 17.2954 20.1274 17.5954 20.1274Z"
                              fill="#130F26"
                              stroke="#130F26"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </g>
                        </g>
                      </svg>
                      <span className="badge rounded-pill badge-primary">
                        {totalItems}
                      </span>
                    </div>

                    {/* --------------Added Cart data start ------------- */}
                    <MyCart/>
                    {/* ---------------- Added Cart End---------------------- */}
                  </li>
                  <li className="onhover-dropdown">
                    <div className="notification-box">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g>
                          <g>
                            <path
                              d="M8.54248 9.21777H15.3975"
                              stroke="#130F26"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M11.9702 2.5C5.58324 2.5 4.50424 3.432 4.50424 10.929C4.50424 19.322 4.34724 21.5 5.94324 21.5C7.53824 21.5 10.1432 17.816 11.9702 17.816C13.7972 17.816 16.4022 21.5 17.9972 21.5C19.5932 21.5 19.4362 19.322 19.4362 10.929C19.4362 3.432 18.3572 2.5 11.9702 2.5Z"
                              stroke="#130F26"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div className="onhover-show-div bookmark-flip">
                      <div className="flip-card">
                        <div className="flip-card-inner">
                          <div className="front dropdown-title">
                            <h3 className="mb-2">Bookmark</h3>
                            <ul className="bookmark-dropdown pb-0">
                              <li className="p-0">
                                <div className="row">
                                  <div className="col-4 text-center">
                                    <div className="bookmark-content">
                                      <div className="bookmark-icon">
                                        <i data-feather="file-text"></i>
                                      </div>
                                      <h5 className="mt-2">
                                        <a href="base-input.html">Forms</a>
                                      </h5>
                                    </div>
                                  </div>
                                  <div className="col-4 text-center">
                                    <div className="bookmark-content">
                                      <div className="bookmark-icon">
                                        <i data-feather="user"></i>
                                      </div>
                                      <h5 className="mt-2">
                                        <a href="user-profile.html">Profile</a>
                                      </h5>
                                    </div>
                                  </div>
                                  <div className="col-4 text-center">
                                    <div className="bookmark-content">
                                      <div className="bookmark-icon">
                                        <i data-feather="server"></i>
                                      </div>
                                      <h5 className="mt-2">
                                        <a href="datatable-basic-init.html">
                                          Tables
                                        </a>
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="text-center">
                                <a
                                  className="flip-btn btn btn-primary"
                                  id="flip-btn"
                                  href="javascript:void(0)"
                                >
                                  Add New Bookmark
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="back dropdown-title">
                            <ul>
                              <li>
                                <div className="bookmark-dropdown flip-back-content">
                                  <input type="text" placeholder="search..." />
                                </div>
                              </li>
                              <li>
                                <a
                                  className="f-w-700 d-block flip-back"
                                  id="flip-back"
                                  href="javascript:void(0)"
                                >
                                  Back
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="onhover-dropdown">
                    <div className="notification-box">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g>
                          <g>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M11.9961 2.51416C7.56185 2.51416 5.63519 6.5294 5.63519 9.18368C5.63519 11.1675 5.92281 10.5837 4.82471 13.0037C3.48376 16.4523 8.87614 17.8618 11.9961 17.8618C15.1152 17.8618 20.5076 16.4523 19.1676 13.0037C18.0695 10.5837 18.3571 11.1675 18.3571 9.18368C18.3571 6.5294 16.4295 2.51416 11.9961 2.51416Z"
                              stroke="#130F26"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M14.306 20.5122C13.0117 21.9579 10.9927 21.9751 9.68604 20.5122"
                              stroke="#130F26"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </g>
                        </g>
                      </svg>
                      <span className="badge rounded-pill badge-warning">4 </span>
                    </div>
                    <div className="onhover-show-div notification-dropdown">
                      <div className="dropdown-title">
                        <h3>Notification</h3>
                        <a className="f-right" href="cart.html">
                          <i data-feather="bell"> </i>
                        </a>
                      </div>
                      <ul className="custom-scrollbar">
                        <li>
                          <div className="media">
                            <div className="notification-img bg-light-primary">
                              <img src="aseets/images/avtar/man.png" alt="" />
                            </div>
                            <div className="media-body">
                              <h5>
                                <a className="f-14 m-0" href="user-profile.html">
                                  Allie Grater
                                </a>
                              </h5>
                              <p>Lorem ipsum dolor sit amet...</p>
                              <span>10:20</span>
                            </div>
                            <div className="notification-right">
                              <a href="/">
                                <i data-feather="x"></i>
                              </a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="media">
                            <div className="notification-img bg-light-secondary">
                              <img
                                src="aseets/images/avtar/teacher.png"
                                alt=""
                              />
                            </div>
                            <div className="media-body">
                              <h5>
                                <a className="f-14 m-0" href="user-profile.html">
                                  Olive Yew
                                </a>
                              </h5>
                              <p>Lorem ipsum dolor sit amet...</p>
                              <span>09:20</span>
                            </div>
                            <div className="notification-right">
                              <a href="/">
                                <i data-feather="x"></i>
                              </a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="media">
                            <div className="notification-img bg-light-info">
                              <img
                                src="aseets/images/avtar/teenager.png"
                                alt=""
                              />
                            </div>
                            <div className="media-body">
                              <h5>
                                <a className="f-14 m-0" href="user-profile.html">
                                  Peg Legge
                                </a>
                              </h5>
                              <p>Lorem ipsum dolor sit amet...</p>
                              <span>07:20</span>
                            </div>
                            <div className="notification-right">
                              <a href="/">
                                <i data-feather="x"></i>
                              </a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="media">
                            <div className="notification-img bg-light-success">
                              <img
                                src="aseets/images/avtar/chinese.png"
                                alt=""
                              />
                            </div>
                            <div className="media-body">
                              <h5>
                                <a className="f-14 m-0" href="user-profile.html">
                                  Teri Dactyl
                                </a>
                              </h5>
                              <p>Lorem ipsum dolor sit amet...</p>
                              <span>05:20</span>
                            </div>
                            <div className="notification-right">
                              <a href="/">
                                <i data-feather="x"></i>
                              </a>
                            </div>
                          </div>
                        </li>
                        <li className="p-0">
                          <a className="btn btn-primary" href="/">
                            Check all
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="maximize">
                    <a
                      className="text-dark"
                      href="#!"
                      onclick="javascript:toggleFullScreen()"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g>
                          <g>
                            <path
                              d="M2.99609 8.71995C3.56609 5.23995 5.28609 3.51995 8.76609 2.94995"
                              stroke="#130F26"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M8.76616 20.99C5.28616 20.41 3.56616 18.7 2.99616 15.22L2.99516 15.224C2.87416 14.504 2.80516 13.694 2.78516 12.804"
                              stroke="#130F26"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M21.2446 12.804C21.2246 13.694 21.1546 14.504 21.0346 15.224L21.0366 15.22C20.4656 18.7 18.7456 20.41 15.2656 20.99"
                              stroke="#130F26"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M15.2661 2.94995C18.7461 3.51995 20.4661 5.23995 21.0361 8.71995"
                              stroke="#130F26"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </g>
                        </g>
                      </svg>
                    </a>
                  </li>
                  <li className="profile-nav onhover-dropdown pe-0 py-0 me-0">
                    <div className="media profile-media">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g>
                          <g>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M9.55851 21.4562C5.88651 21.4562 2.74951 20.9012 2.74951 18.6772C2.74951 16.4532 5.86651 14.4492 9.55851 14.4492C13.2305 14.4492 16.3665 16.4342 16.3665 18.6572C16.3665 20.8802 13.2505 21.4562 9.55851 21.4562Z"
                              stroke="#130F26"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M9.55849 11.2776C11.9685 11.2776 13.9225 9.32356 13.9225 6.91356C13.9225 4.50356 11.9685 2.54956 9.55849 2.54956C7.14849 2.54956 5.19449 4.50356 5.19449 6.91356C5.18549 9.31556 7.12649 11.2696 9.52749 11.2776H9.55849Z"
                              stroke="#130F26"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M16.8013 10.0789C18.2043 9.70388 19.2383 8.42488 19.2383 6.90288C19.2393 5.31488 18.1123 3.98888 16.6143 3.68188"
                              stroke="#130F26"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M17.4608 13.6536C19.4488 13.6536 21.1468 15.0016 21.1468 16.2046C21.1468 16.9136 20.5618 17.6416 19.6718 17.8506"
                              stroke="#130F26"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <ul className="profile-dropdown onhover-show-div">
                      <li>
                        <a href="user-profile.html">
                          <i data-feather="user"></i>
                          <span>Account </span>
                        </a>
                      </li>
                      <li>
                        <a href="email-application.html">
                          <i data-feather="mail"></i>
                          <span>Inbox</span>
                        </a>
                      </li>
                      <li>
                        <a href="kanban.html">
                          <i data-feather="file-text"></i>
                          <span>Taskboard</span>
                        </a>
                      </li>
                      <li>
                        <a href="edit-profile.html">
                          <i data-feather="settings"></i>
                          <span>Settings</span>
                        </a>
                      </li>
                      <li>
                        <a href="login.html">
                          <i data-feather="log-in"> </i>
                          <span>Log in</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* ===========Page Body Start ======== */}

          {/* ----------------Page Sidebar Start----------- */}
          {/* <PageSideBar /> */}
          {/* ----------------Page Sidebar End----------- */}
          {/* <PageBody/> */}
        </div>
        {/* ===========Page Body End ======== */}

        {/* -----------footer----------= */}

        {/* <!-- Page Header End--> */}
      </div>
    </div>
  );
};

export default PageHeader;
