import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useCart } from "react-use-cart";
import { MyContext } from "../../App";

const ProductDetails = (props) => {
    const [isOpen, , , ] = useContext(MyContext);
  const { productId } = useParams();
  const {addItem} = useCart();

  const [product, setProduct] = useState({});
  useEffect(() => {
    fetch(`https://amvines.com/api/product/${productId}`)
      .then((res) => res.json())
      .then((data) => setProduct(data.result.data));
  }, []);
  console.log(product,"product details");

  // Scroll to the top of the page after render
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div className={`page-wrapper ${isOpen ? "compact-wrapper" : ""}`} id="pageWrapper">
       <div className="page-body-wrapper">
      <div className="page-body">
        <div className="container-fluid">
          <div className="page-title">
            <div className="row">
              <div className="col-12 col-sm-6">
                <h3>Product Page</h3>
              </div>
              <div className="col-12 col-sm-6">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-home"
                      >
                        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                        <polyline points="9 22 9 12 15 12 15 22"></polyline>
                      </svg>
                    </Link>
                  </li>
                  <li className="breadcrumb-item">E-Commerce</li>
                  <li className="breadcrumb-item active">Product Page</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div>
            <div className="row product-page-main p-0">
              <div className="col-xl-5 col-md-6 box-col-6 xl-50">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-xl-9 box-col-7 product-main">
                        <div className="pro-slide-single slick-initialized slick-slider">
                          <div className="slick-list draggable">
                            <div
                              className="slick-track"
                              style={{ opacity: 1, width: "4136px" }}
                            >
                              <div
                                className="slick-slide slick-current slick-active"
                                data-slick-index="0"
                                aria-hidden="false"
                                tabindex="0"
                                style={{
                                  width: "417px",
                                  position: "relative",
                                  left: "0px",
                                  top: "0px",
                                  opacity: 1,
                                }}
                              >
                                <img
                                  className="img-fluid"
                                  src={product.image ? `https://amvines.com/banner/${product.image}` : "https://as2.ftcdn.net/v2/jpg/03/08/68/19/1000_F_308681935_VSuCNvhuif2A8JknPiocgGR2Ag7D1ZqN.jpg"}
                                  alt=""
                                />
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 box-col-4 product-thumbnail">
                        <div className="pro-slide-right slick-initialized slick-slider slick-vertical">
                          <div
                            className="slick-list draggable"
                            style={{ height: "486px" }}
                          >
                            <div
                              className="slick-track"
                              style={{
                                opacity: "1",
                                transform: "tranlate3d(0px, -486px, 0px)",
                              }}
                            >
                              <div
                                className="slick-slide slick-cloned"
                                data-slick-index="-3"
                                id=""
                                aria-hidden="true"
                                tabindex="-1"
                                style={{ width: "157px" }}
                              >
                                {/* product side images show  */}
                                {
                                    product?.images?.slice(0,3).map(item=> <div className="slide-box" key={item.id}>
                                    <img
                                      src={item}
                                      alt=""
                                      style={{width: "157px" }}
                                    />
                                  </div>)
                                }
                                {/* <div className="slide-box">
                                  <img
                                    src="assets/images/ecommerce/06.jpg"
                                    alt=""
                                  />
                                </div> */}
                              </div>
         
                             
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 box-col-12 proorder-xl-3 xl-100">
                <div className="card">
                  <div className="card-body">
                    <div className="pro-group pt-0 border-0">
                      <div className="product-page-details mt-0">
                        <h3>{product.title}</h3>
                      </div>
                      <div className="product-price">
                        ${product.sale_price}
                        <del>${product.price} </del>
                      </div>
                      <ul className="product-color"></ul>
                      <a className="btn btn-secondary mt-0" href="cart.html">
                        <i className="fa fa-shopping-cart me-2"></i>Buy Now
                      </a>
                    </div>
                    <div className="pro-group">
                      <p>
                      {product.description}
                      </p>
                    </div>
                    <div className="pro-group">
                      <div className="row">
                        <div className="col-md-6">
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  {" "}
                                  <b>Availability &nbsp;: &nbsp;</b>
                                </td>
                                <td className="txt-success">In stock</td>
                              </tr>
                              <tr>
                                <td>
                                  {" "}
                                  <b>
                                    Brand
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                                  </b>
                                </td>
                                <td>Pixelstrap</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="col-md-6">
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  {" "}
                                  <b>
                                    Generic &nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;:
                                    &nbsp;&nbsp;&nbsp;
                                  </b>
                                </td>
                                <td>ABC</td>
                              </tr>
                              <tr>
                                <td>
                                  {" "}
                                  <b>
                                    Fabric &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;
                                  </b>
                                </td>
                                <td>Cotton </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="pro-group pb-0">
                      <div className="pro-shop">
                        <button onClick={()=>addItem(product)} className="btn btn-primary m-r-10">
                          <i className="fa fa-shopping-basket me-2"></i>Add To Cart
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-md-6 box-col-6 xl-50 proorder-lg-1">
                <div className="card">
                  <div className="card-body">
                    {/* <!-- side-bar colleps block stat--> */}
                    <div className="filter-block">
                      <h4>Brand </h4>
                      <ul>
                        <li>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              id="Raymond"
                              type="checkbox"
                              value=""
                            />
                            <label className="form-check-label" for="Raymond">
                              Raymond
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              id="Pepe-Jeans"
                              type="checkbox"
                              value=""
                            />
                            <label className="form-check-label" for="Pepe-Jeans">
                              Pepe-Jeans
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              id="Celio"
                              type="checkbox"
                              value=""
                            />
                            <label className="form-check-label" for="Celio">
                              Celio
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              id="aime"
                              type="checkbox"
                              value=""
                            />
                            <label className="form-check-label" for="aime">
                              aime
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              id="aliff"
                              type="checkbox"
                              value=""
                            />
                            <label className="form-check-label" for="aliff">
                              aliff
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="collection-filter-block">
                      <ul className="pro-services">
                        <li>
                          <div className="media">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="feather feather-truck"
                            >
                              <rect x="1" y="3" width="15" height="13"></rect>
                              <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
                              <circle cx="5.5" cy="18.5" r="2.5"></circle>
                              <circle cx="18.5" cy="18.5" r="2.5"></circle>
                            </svg>
                            <div className="media-body">
                              <h5>Free Shipping</h5>
                              <p>Free Shipping World Wide</p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="media">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="feather feather-clock"
                            >
                              <circle cx="12" cy="12" r="10"></circle>
                              <polyline points="12 6 12 12 16 14"></polyline>
                            </svg>
                            <div className="media-body">
                              <h5>24 X 7 Service </h5>
                              <p>Online Service For New Customer </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <!-- silde-bar colleps block end here--> */}
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="row product-page-main">
              <div className="col-sm-12">
                <ul
                  className="nav nav-tabs border-tab mb-0"
                  id="top-tab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="top-home-tab"
                      data-bs-toggle="tab"
                      href="#top-home"
                      role="tab"
                      aria-controls="top-home"
                      aria-selected="false"
                    >
                      Category
                    </a>
                    <div className="material-border"></div>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="profile-top-tab"
                      data-bs-toggle="tab"
                      href="#top-profile"
                      role="tab"
                      aria-controls="top-profile"
                      aria-selected="false"
                    >
                      Video
                    </a>
                    <div className="material-border"></div>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="contact-top-tab"
                      data-bs-toggle="tab"
                      href="#top-contact"
                      role="tab"
                      aria-controls="top-contact"
                      aria-selected="false"
                    >
                      Details
                    </a>
                    <div className="material-border"></div>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="brand-top-tab"
                      data-bs-toggle="tab"
                      href="#top-brand"
                      role="tab"
                      aria-controls="top-brand"
                      aria-selected="true"
                    >
                      Brand
                    </a>
                    <div className="material-border"></div>
                  </li>
                </ul>
                <div className="tab-content" id="top-tabContent">
                  <div
                    className="tab-pane fade"
                    id="top-home"
                    role="tabpanel"
                    aria-labelledby="top-home-tab"
                  >
                    <p className="mb-0 m-t-20">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum."
                    </p>
                    <p className="mb-0 m-t-20">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum."
                    </p>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="top-profile"
                    role="tabpanel"
                    aria-labelledby="profile-top-tab"
                  >
                    <p className="mb-0 m-t-20">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum
                    </p>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="top-contact"
                    role="tabpanel"
                    aria-labelledby="contact-top-tab"
                  >
                    <p className="mb-0 m-t-20">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum
                    </p>
                  </div>
                  <div
                    className="tab-pane fade active show"
                    id="top-brand"
                    role="tabpanel"
                    aria-labelledby="brand-top-tab"
                  >
                    <p className="mb-0 m-t-20">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Container-fluid Ends--> */}
      </div>
      </div>
      </div>
  );
};

export default ProductDetails;
