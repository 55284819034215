import React from "react";
import { useCart } from "react-use-cart";

const MyCart = () => {
  const {
    isEmpty,
    items,
    updateItemQuantity,
    removeItem,
    emptyCart,
  } = useCart();

   // Total Sale Price Calculation with reduce
   const cartTotal = items.reduce((prev,curr) =>prev + curr.sale_price * curr.quantity,0)

  return (
      <div className="cart-dropdown onhover-show-div">
        <div className="dropdown-title">
          <h3>My Cart</h3>
          <a className="f-right" href="cart.html">
            <i data-feather="shopping-bag"> </i>
          </a>
        </div>
        <ul className="custom-scrollbar">
          {/* ------------------------------ */}
          {items.map((cart) => (
            <div key={cart.id}>
              <li>
                <div className="media">
                  <img
                    className="img-fluid b-r-5 me-3 img-50"
                    src={cart.thumbnail}
                    alt=""
                  />
                  <div className="media-body">
                    <h6>
                      <a
                        className="text-decoration-none text-dark"
                        href="product.html"
                      >
                        {cart.title}
                      </a>
                    </h6>
                    <p className="f-w-700">${cart.sale_price}</p>
                    <div className="cart-quantity">
                      <span
                        onClick={() =>
                          updateItemQuantity(cart.id, cart.quantity - 1)
                        }
                        className="minus"
                      >
                        <i className="fa fa-minus"></i>
                      </span>
                      <input
                        className="count"
                        type="text"
                        name="qty"
                        value={cart.quantity}
                      />

                      <span
                        onClick={() =>
                          updateItemQuantity(cart.id, cart.quantity + 1)
                        }
                        className="plus"
                      >
                        <i className="fa fa-plus"></i>
                      </span>
                    </div>
                  </div>
                  <h6
                    className="text-danger mx-3"
                    onClick={() => removeItem(cart.id)}
                  >
                    <i class="fa-solid fa-xmark"></i>
                  </h6>
                  <div className="close-circle">
                    <a href="/">
                      <i className="me-1" data-feather="edit"></i>
                    </a>
                    <a href="/">
                      <i data-feather="trash-2"></i>
                    </a>
                  </div>
                </div>
              </li>
            </div>
          ))}
          {/* //-------------------------------// */}

          {isEmpty ? (
            <h3 className="text-secondary ms-5 mt-5">
              Empty Cart <i className="fa-brands fa-opencart"></i>
            </h3>
          ) : (
            <>
              {" "}
              <li className="order-total">
                <h4 className="mb-0 f-w-700">
                  Total : <span>${cartTotal}</span>
                </h4>
                <a
                  className="btn btn-primary view-checkout"
                  href="checkout.html"
                >
                  Checkout
                </a>
              </li>
              <li>
                <button className="btn btn-danger" onClick={emptyCart}>
                  Clear All <i className="fa-regular fa-trash-can"></i>
                </button>
              </li>
            </>
          )}
        </ul>
      </div>
  );
};

export default MyCart;
