import React, { useContext } from 'react';
import { MyContext } from '../../App';

const PageFooter = () => {
   const [isOpen] = useContext(MyContext);
    return (
        <div>
                 <footer className={`${isOpen ? "footer" : ""} bg-gray`} style={{background: "#F2F5F5"}}>
               <div className="container-fluid">
                  <div className="row">
                     <div className="col-md-12 footer-copyright text-center">
                        <p className="mb-0">Copyright 2022 © muhammad asif ul islam  </p>
                     </div>
                  </div>
                  <div className="row mt-5">
                     <div className="col-md-3">
                        <h4>INFORMATION</h4>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, commodi, earum doloribus ex quasi accusamus minima aliquid fugit alias, non voluptate placeat dolores velit ut dignissimos delectus soluta nemo sapiente!
                        </p>
                     </div>
                     <div className="col-md-3">
                        <h4>USEFUL LINKS</h4>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, commodi, earum doloribus ex quasi accusamus minima aliquid fugit alias, non voluptate placeat dolores velit ut dignissimos delectus soluta nemo sapiente!
                        </p>
                     </div>
                     <div className="col-md-3">
                        <h4>CONTACT US</h4>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, commodi, earum doloribus ex quasi accusamus minima aliquid fugit alias, non voluptate placeat dolores velit ut dignissimos delectus soluta nemo sapiente!
                        </p>
                     </div>
                     <div className="col-md-3">
                        <h4>DOWNLOAD OUR APP</h4>
                        <a href="/"><img src="https://cdn6.arogga.com/img/google-play-badge.png" alt=""/> </a>
                        <br/>
                        <a href="/"><img className="mt-2" src="https://cdn6.arogga.com/img/apple-store-badge.png" alt=""/>+</a>
                     </div>
                  </div>
                  <div className="row">
                    <div className="col-8 offset-1">
                        <img src="https://cdn6.arogga.com/img/payment-options.png" style={{width: "1200px", height: "120px"}} alt=""/>
                    </div>
                  </div>

               </div>
            </footer>
        </div>
    );
};

export default PageFooter;