import React, { useContext, useEffect, useState } from "react";
import { MyContext } from "../../App";
import SingleProducts from "../SingleProduct/SingleProducts";

const HomePageProducts = () => {
  const [isOpen] = useContext(MyContext)
  const [homeProducts,setHomeProducts] = useState([]);
  useEffect(()=>{
    fetch("https://amvines.com/api/homepage")
    .then(res=>res.json())
    .then(data=>setHomeProducts(data.result))
  },[])
  // console.log(homeProducts);
  // console.log(Object?.getOwnPropertyNames(homeProducts));
  return (
    <div>
      <div className={`page-wrapper ${isOpen ? "compact-wrapper" : ""}`} id="pageWrapper">
      <div className="page-body-wrapper">
      <div className="page-body">
       <div class="container-fluid">
      
           <div class="col-xl-12 col-lg-12 col-md-7 xl-65">
        <div class="product-wrapper-grid">
          <div class="row">
            <h1>New Products</h1>
            {
              homeProducts?.newProducts?.map(product => <SingleProducts product={product}/>)
            }
            <h1>Populars Products</h1>
            {
              homeProducts?.popularProducts?.map(product => <SingleProducts product={product}/>)
            }
            <h1>Top Sale Products</h1>
            {
              homeProducts?.topSaleProducts?.map(product => <SingleProducts product={product}/>)
            }
            
            
          </div>
        </div>
    
           </div>
           
        </div>
       </div>
       </div>
       </div>
       </div>

  );
};

export default HomePageProducts;
