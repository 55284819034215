import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { MyContext } from "../../App";

const CategoryProducts = () => {
    const [categoryProducts,setCategoryProducts] = useState([]);
    const [isOpen] = useContext(MyContext)
  useEffect(() => {
    fetch("https://amvines.com/api/category")
      .then((res) => res.json())
      .then((data) => setCategoryProducts(data.result));
  }, []);
  console.log(categoryProducts,"0202020");
  return (
    <div className={` ${isOpen ? "container" : ""}`}>
      <h1 className="m-5 p-5">All Category</h1>
      <Row xs={1} md={3} className="g-3 mx-5">
        {categoryProducts.map((product, idx) => (
          <Col key={product.id}>
            <Card>
              <Card.Img variant="top" src={`https://amvines.com/storage/category/${product.image}`} alt="" height={"400px"} />
              <Card.Body>
                <Card.Title>{product.name}</Card.Title>
                {/* <Card.Text>
                  This is a longer card with supporting text below as a natural
                  lead-in to additional content. This content is a little bit
                  longer.
                </Card.Text> */}
                {/* {product.subcategories.map(sub=><li key={sub.id}>{sub.name}</li>)} */}
              </Card.Body>
              
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default CategoryProducts;
