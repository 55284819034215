import React, { useContext, useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MyContext } from "../../App";
import "./PageSideBar.css";
import { Scrollbars } from "react-custom-scrollbars-2";

const PageSideBar = (props) => {
  // const {isOpen} = props;
  const [isOpen] = useContext(MyContext);
  const [categoryProducts, setCategoryProducts] = useState([]);

  useEffect(() => {
    fetch("https://amvines.com/api/category")
      .then((res) => res.json())
      .then((data) => setCategoryProducts(data.result));
  }, []);
  return (
    <div>
      <div
        className={`page-wrapper compact-wrapper ${isOpen ? "" : "d-none"}`}
        id="pageWrapper"
      >
        <div className="page-body-wrapper">
          {/* <!-- Page Sidebar Start--> */}
          <div className="sidebar-wrapper">
                <div className="logo-wrapper">
                  <Link to="/">
                    <img
                      style={{ height: "50px" }}
                      className="img-fluid for-light"
                      src="assets/images/logo/small-logo.png"
                      alt=""
                    />
                    <img
                      className="img-fluid for-dark"
                      src="assets/images/logo/small-white-logo.png"
                      alt=""
                    />
                  </Link>
                  <div className="back-btn">
                    <i className="fa fa-angle-left"></i>
                  </div>
                </div>
            <Scrollbars style={{ height: "80%" }}>
              <div>
                {/* --------------------------------------------sidebar menu start     --------- */}
                <div className="logo-icon-wrapper">
                  <a href="index.html">
                    <img
                      className="img-fluid"
                      src="assets/images/logo-icon.png"
                      alt=""
                    />
                  </a>
                </div>
                {/* <div id="sidebar-menu mt-5">
                  <ul className="sidebar-links" id="simple-bar">
                    <li className="back-btn">
                      <a href="index.html">
                        <img
                          className="img-fluid"
                          src="assets/images/logo-icon.png"
                          alt=""
                        />
                      </a>
                      <div className="mobile-back text-end">
                        <span>Back</span>
                        <i className="fa fa-angle-right ps-2" aria-hidden="true">
                          {" "}
                        </i>
                      </div>
                    </li>
                    <li className="mt-1" style={{ visibility: "" }}>
                      fahid
                    </li>
                  </ul>
                </div> */}
                {/* 
              <div className="d-flex ms-3 align-items-center mt-0 navigation_menu">
                <div>
                  <a className="sidebar-link sidebar-title" href="/">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <g>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M2.75024 12C2.75024 5.063 5.06324 2.75 12.0002 2.75C18.9372 2.75 21.2502 5.063 21.2502 12C21.2502 18.937 18.9372 21.25 12.0002 21.25C5.06324 21.25 2.75024 18.937 2.75024 12Z"
                            stroke="#130F26"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M15.2045 13.8999H15.2135"
                            stroke="#130F26"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M12.2045 9.8999H12.2135"
                            stroke="#130F26"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M9.19557 13.8999H9.20457"
                            stroke="#130F26"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </a>
                </div>
                <div className="navigation_div">
                  <Link
                    className="text-decoration-none   ms-2 fw-bold navigation_link"
                    to="/"
                  >
                    - Covid-19 Special --
                  </Link>
                </div>
              </div>
              <div className="d-flex ms-3 align-items-center mt-1 navigation_menu">
                <div>
                  {" "}
                  <a className="sidebar-link sidebar-title" href="/">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <g>
                          <path
                            d="M15.596 15.6963H8.37598"
                            stroke="#130F26"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M15.596 11.9365H8.37598"
                            stroke="#130F26"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M11.1312 8.17725H8.37622"
                            stroke="#130F26"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.61011 12C3.61011 18.937 5.70811 21.25 12.0011 21.25C18.2951 21.25 20.3921 18.937 20.3921 12C20.3921 5.063 18.2951 2.75 12.0011 2.75C5.70811 2.75 3.61011 5.063 3.61011 12Z"
                            stroke="#130F26"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </a>
                </div>
                <div className="navigation_div">
                  <Link
                    className="text-decoration-none   ms-2 fw-bold navigation_link"
                    to="/"
                  >
                    - Devices
                  </Link>
                </div>
              </div>
              <div className="d-flex ms-3 align-items-center mt-1 navigation_menu">
                <div>
                  {" "}
                  <a className="sidebar-link sidebar-title" href="/">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <g>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M2.75024 12C2.75024 5.063 5.06324 2.75 12.0002 2.75C18.9372 2.75 21.2502 5.063 21.2502 12C21.2502 18.937 18.9372 21.25 12.0002 21.25C5.06324 21.25 2.75024 18.937 2.75024 12Z"
                            stroke="#130F26"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M15.2045 13.8999H15.2135"
                            stroke="#130F26"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M12.2045 9.8999H12.2135"
                            stroke="#130F26"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M9.19557 13.8999H9.20457"
                            stroke="#130F26"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </a>
                </div>
                <div className="navigation_div">
                  <Link
                    className="text-decoration-none   ms-2 fw-bold navigation_link"
                    to="/products"
                  >
                    - Herbal & Homeopathy
                  </Link>
                </div>
              </div>
              <div className="d-flex ms-3 align-items-center mt-1 navigation_menu">
                <div>
                  {" "}
                  <a className="sidebar-link sidebar-title" href="/">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <g>
                          <path
                            d="M15.596 15.6963H8.37598"
                            stroke="#130F26"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M15.596 11.9365H8.37598"
                            stroke="#130F26"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M11.1312 8.17725H8.37622"
                            stroke="#130F26"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.61011 12C3.61011 18.937 5.70811 21.25 12.0011 21.25C18.2951 21.25 20.3921 18.937 20.3921 12C20.3921 5.063 18.2951 2.75 12.0011 2.75C5.70811 2.75 3.61011 5.063 3.61011 12Z"
                            stroke="#130F26"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </a>
                </div>
                <div className="navigation_div">
                  <Link
                    className="text-decoration-none   ms-2 fw-bold navigation_link"
                    to="/"
                  >
                    - Natural Care
                  </Link>
                </div>
              </div>
              <div className="d-flex ms-3 align-items-center mt-1 navigation_menu">
                <div>
                  {" "}
                  <a className="sidebar-link sidebar-title" href="/">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <g>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M2.75024 12C2.75024 5.063 5.06324 2.75 12.0002 2.75C18.9372 2.75 21.2502 5.063 21.2502 12C21.2502 18.937 18.9372 21.25 12.0002 21.25C5.06324 21.25 2.75024 18.937 2.75024 12Z"
                            stroke="#130F26"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M15.2045 13.8999H15.2135"
                            stroke="#130F26"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M12.2045 9.8999H12.2135"
                            stroke="#130F26"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M9.19557 13.8999H9.20457"
                            stroke="#130F26"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </a>
                </div>
                <div className="navigation_div">
                  <Link
                    className="text-decoration-none   ms-2 fw-bold navigation_link"
                    to="/products"
                  >
                    - Personal Care
                  </Link>
                </div>
              </div>
              <div className="d-flex ms-3 align-items-center mt-1 navigation_menu">
                <div>
                  {" "}
                  <a className="sidebar-link sidebar-title" href="/">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <g>
                          <path
                            d="M15.596 15.6963H8.37598"
                            stroke="#130F26"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M15.596 11.9365H8.37598"
                            stroke="#130F26"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M11.1312 8.17725H8.37622"
                            stroke="#130F26"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.61011 12C3.61011 18.937 5.70811 21.25 12.0011 21.25C18.2951 21.25 20.3921 18.937 20.3921 12C20.3921 5.063 18.2951 2.75 12.0011 2.75C5.70811 2.75 3.61011 5.063 3.61011 12Z"
                            stroke="#130F26"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </a>
                </div>
                <div className="navigation_div">
                  <Link
                    className="text-decoration-none   ms-2 fw-bold navigation_link"
                    to="/"
                  >
                    - Skin Care
                  </Link>
                </div>
              </div>
              <div className="d-flex ms-3 align-items-center mt-1 navigation_menu">
                <div>
                  {" "}
                  <a className="sidebar-link sidebar-title" href="/">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <g>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M2.75024 12C2.75024 5.063 5.06324 2.75 12.0002 2.75C18.9372 2.75 21.2502 5.063 21.2502 12C21.2502 18.937 18.9372 21.25 12.0002 21.25C5.06324 21.25 2.75024 18.937 2.75024 12Z"
                            stroke="#130F26"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M15.2045 13.8999H15.2135"
                            stroke="#130F26"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M12.2045 9.8999H12.2135"
                            stroke="#130F26"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M9.19557 13.8999H9.20457"
                            stroke="#130F26"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </a>
                </div>
                <div className="navigation_div">
                  <Link
                    className="text-decoration-none   ms-2 fw-bold navigation_link"
                    to="/products"
                  >
                    - Personal Care
                  </Link>
                </div>
              </div>
              <div className="d-flex ms-3 align-items-center mt-1 navigation_menu">
                <div>
                  {" "}
                  <a className="sidebar-link sidebar-title" href="/">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <g>
                          <path
                            d="M15.596 15.6963H8.37598"
                            stroke="#130F26"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M15.596 11.9365H8.37598"
                            stroke="#130F26"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M11.1312 8.17725H8.37622"
                            stroke="#130F26"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.61011 12C3.61011 18.937 5.70811 21.25 12.0011 21.25C18.2951 21.25 20.3921 18.937 20.3921 12C20.3921 5.063 18.2951 2.75 12.0011 2.75C5.70811 2.75 3.61011 5.063 3.61011 12Z"
                            stroke="#130F26"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </a>
                </div>
                <div className="navigation_div">
                  <Link
                    className="text-decoration-none   ms-2 fw-bold navigation_link"
                    to="/"
                  >
                    - Health Care --
                  </Link>
                </div>
              </div> */}

                <div>
                  {categoryProducts.map((product) => (
                    <div key={product.id}>
                      <Accordion className="mt-0">
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            <a className="sidebar-link sidebar-title" href="/">
                            <img className="border rounded-pill" src={`https://amvines.com/storage/category/${product?.image}`} alt="product-img" width={"24"} height={"24"}/>
                            </a>
                            <span className="lan-7 ms-2 text-secondary fw-bold">
                              {product.name}
                            </span>
                          </Accordion.Header>
                          <Accordion.Body className="m-0">
                         {
                          product.subcategories.map(item=> <div key={item.id}>
                            <div className="navigation_div">
                              <Link
                                className="text-decoration-none fw-600 navigation_link"
                                to={item.slug}
                              >
                                <img className="border rounded-pill" src={ item.image ? `https://amvines.com/storage/subcategory/${item?.image}` : "https://as2.ftcdn.net/v2/jpg/03/08/68/19/1000_F_308681935_VSuCNvhuif2A8JknPiocgGR2Ag7D1ZqN.jpg"} alt="" width={"24"} height={"24"}/>
                                <span className="ms-2" style={{fontSize:"0.8rem"}}>{item?.name}</span>
                              </Link>
                            </div>
                            </div>
                  
                          )
                        }
                        </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  ))}
                </div>

                {/* --------------------------------------------sidebar menu end--------- */}
              </div>
            </Scrollbars>
            {/* -----------Master man---------- */}
            <div
              className="sidebar-img-section mt-3"
              style={{ position: "absolute", bottom: "" }}
            >
              <h2>
                masterMan&nbsp;
                <i className="fa-sharp fa-solid fa-circle-arrow-down"></i>
              </h2>
            </div>
            {/* -----------Master man---------- */}
          </div>
          {/* <!-- Page Sidebar Ends--> */}
        </div>
      </div>
    </div>
  );
};

export default PageSideBar;
