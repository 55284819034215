import React, { useContext } from 'react';
import { MyContext } from '../../App';
import OffCanvas from '../OffCanvas/OffCanvas';

const HomePageSlider = () => {
   const [isOpen] = useContext(MyContext);
    return (
        <div>
              <div className={`page-wrapper ${isOpen ? "compact-wrapper" : ""}`} id="pageWrapper">
            <div className="page-body-wrapper">
             <div className="page-body">
            <div className="container-fluid">
                  <div className="user-profile">
                     <div className="row">
                        {/* <!-- slider part start  --> */}
                        <div className="col-xl-12 col-lg-12 col-md-12 xl-65">
                           <div className="row">
                              {/* <!-- profile post start--> */}
                              <div className="col-sm-12 mt-2">
                                 <div className="card">
                                    <div className="profile-post1">
                                       <div className="">
                                          <div className="container1">
                                             <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                                                <ol className="carousel-indicators">
                                                   <li style={{background: "#E86649"}} data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                                                   <li style={{background: "#E86649"}} data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                                   <li style={{background: "#E86649"}} data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                                                   <li style={{background: "#E86649"}} data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                                                   <li style={{background: "#E86649"}} data-target="#carouselExampleIndicators" data-slide-to="4"></li>
                                                   <li style={{background: "#E86649"}} data-target="#carouselExampleIndicators" data-slide-to="5"></li>
                                                </ol>
                                                <div className="carousel-inner">
                                                   <div className="carousel-item active">
                                                      <img className="d-block w-100" src="https://cdn1.arogga.com/eyJidWNrZXQiOiJhcm9nZ2EiLCJrZXkiOiJvcHRpb25cL2F0dGFjaGVkRmlsZXNXZWItXC9mcGJydmEucG5nIiwiZWRpdHMiOnsicmVzaXplIjp7IndpZHRoIjoxNDA1LCJoZWlnaHQiOjQ1MCwiZml0Ijoib3V0c2lkZSJ9fX0=" alt="First slide"/>
                                                   </div>
                                                   <div className="carousel-item">
                                                      <img className="d-block w-100" src="https://cdn1.arogga.com/eyJidWNrZXQiOiJhcm9nZ2EiLCJrZXkiOiJvcHRpb25cL2F0dGFjaGVkRmlsZXNXZWItXC9zdHJpa3AucG5nIiwiZWRpdHMiOnsicmVzaXplIjp7IndpZHRoIjoxNDA1LCJoZWlnaHQiOjQ1MCwiZml0Ijoib3V0c2lkZSJ9fX0=" alt="Second slide"/>
                                                   </div>
                                                   <div className="carousel-item">
                                                      <img className="d-block w-100" src="https://cdn1.arogga.com/eyJidWNrZXQiOiJhcm9nZ2EiLCJrZXkiOiJvcHRpb25cL2F0dGFjaGVkRmlsZXNXZWItXC91d3JpYjEucG5nIiwiZWRpdHMiOnsicmVzaXplIjp7IndpZHRoIjoxNDA1LCJoZWlnaHQiOjQ1MCwiZml0Ijoib3V0c2lkZSJ9fX0=" alt="Third slide"/>
                                                   </div>
                                                   <div className="carousel-item">
                                                      <img className="d-block w-100" src="https://cdn1.arogga.com/eyJidWNrZXQiOiJhcm9nZ2EiLCJrZXkiOiJvcHRpb25cL2F0dGFjaGVkRmlsZXNXZWItXC8xeDZ4dm0ucG5nIiwiZWRpdHMiOnsicmVzaXplIjp7IndpZHRoIjoxNDA1LCJoZWlnaHQiOjQ1MCwiZml0Ijoib3V0c2lkZSJ9fX0=" alt="Third slide"/>
                                                   </div>
                                                   <div className="carousel-item">
                                                      <img className="d-block w-100" src="https://cdn1.arogga.com/eyJidWNrZXQiOiJhcm9nZ2EiLCJrZXkiOiJvcHRpb25cL2F0dGFjaGVkRmlsZXNXZWItXC94NnIxcW4ucG5nIiwiZWRpdHMiOnsicmVzaXplIjp7IndpZHRoIjoxNDA1LCJoZWlnaHQiOjQ1MCwiZml0Ijoib3V0c2lkZSJ9fX0=" alt="Third slide"/>
                                                   </div>
                                                   <div className="carousel-item">
                                                      <img className="d-block w-100" src="https://cdn1.arogga.com/eyJidWNrZXQiOiJhcm9nZ2EiLCJrZXkiOiJvcHRpb25cL2F0dGFjaGVkRmlsZXNXZWItXC9iNmZidWEucG5nIiwiZWRpdHMiOnsicmVzaXplIjp7IndpZHRoIjoxNDA1LCJoZWlnaHQiOjQ1MCwiZml0Ijoib3V0c2lkZSJ9fX0=" alt="Third slide"/>
                                                   </div>
                                                </div>
                                                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span className="sr-only">Previous</span>
                                                </a>
                                                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span className="sr-only">Next</span>
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
         {/* ----------------------OffCanvas------------------------ */}
               <OffCanvas/>
         {/* ----------------------OffCanvas------------------------ */}
                              </div>
                              {/* <!-- profile post end--> */}
                           </div>
                        </div>

                        {/* <!-- slider part end  --> */}
                        <div className="col-xl-4 col-sm-4 col-4 box-col-6 lg-100">
                           <div className="row">
                              <div className="col-xl-12 col-lg-6 box-col-6 lg-50">
                                 <div className="card testimonial" style={{border:"3px solid rgb(211, 176,224)"}}>
                                    <div className="card-body">
                                       <img className="img-90 user-img" src="assets/images/pescription/prescription.png" alt=""/>
                                       <h4 className="mt-2" style={{color: "#E86549"}}>
                                          Upto 10% off + cashback.                           
                                       </h4>
                                       <h5>Upload Pescription</h5>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-4 col-sm-4 col-4 box-col-6 lg-100">
                           <div className="row">
                              <div className="col-xl-12 col-lg-6 box-col-6 lg-50">
                                 <div className="card testimonial"  style={{border:"3px solid rgb(248, 164,76)"}}>
                                    <div className="card-body">
                                       <img className="img-90 user-img" src="assets/images/pescription/customer-service.png" alt=""/>
                                       <h4 className="mt-2" style={{color: "#E86549"}}>
                                          Upto 10% off + cashback.                           
                                       </h4>
                                       <h5>Call for order </h5>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-4 col-sm-4 col-4 box-col-6 lg-100">
                           <div className="row">
                              <div className="col-xl-12 col-lg-6 box-col-6 lg-50">
                                 <div className="card testimonial" style={{border:"3px solid rgb(83, 177, 117)"}}>
                                    <div className="card-body">
                                       <img className="img-90 user-img" src="assets/images/pescription/label.png" alt=""/>
                                       <h4 className="mt-2" style={{color: "#E86549"}}>
                                          Upto 10% off + cashback.                           
                                       </h4>
                                       <h5>Healthcare products</h5>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
        </div>
    );
};

export default HomePageSlider;