import React from 'react';
import { Link } from 'react-router-dom';
import { useCart } from 'react-use-cart';

const SingleProducts = (props) => {
    const {title,thumbnail,description,price,image,sale_price,id} = props.product;
    const {addItem} = useCart();
    return (
            
            // <div className="col-xl-2 col-sm-6 col-6">
            //           <div className="card">
            //             <div className="product-box">
            //               <div className="product-img">
            //                 <img
            //                   className="img-fluid"
            //                   src={thumbnail}
            //                   alt=""
            //                   style={{height:"200px",width:'100%'}}
                              
            //                 />
            //                 <div className="product-hover">
            //                   <ul>
            //                     <li onClick={()=>addItem(props.product)}>
            //                       <Link to=''
            //                         ><i className="fa-solid fa-cart-shopping"></i></Link>
            //                     </li>
            //                     <li>
            //                       <Link to={`product_details/${id}`}><i className="fa-regular fa-eye"></i>
            //                         </Link>
            //                     </li>
            //                   </ul>
            //                 </div>
            //               </div>
            //               <div className="product-details">
            //                 {/* <div className="rating">
            //                   <i className="fa fa-star"></i
            //                   ><i className="fa fa-star"></i
            //                   ><i className="fa fa-star"></i
            //                   ><i className="fa fa-star"></i
            //                   ><i className="fa fa-star"></i>
            //                 </div> */}
            //                 <Link className='text-decoration-none' to={`product_details/${id}`}>
            //                   <h4>{title.slice(0,12)}</h4>
            //                 </Link>
            //                 <p>{description.slice(0,40)}</p>
            //                 <div className="product-price">
            //                   ${price}
            //                   <del>$35.00</del>
            //                 </div>
            //               </div>
            //             </div>
            //           </div>
            //         </div>
            <div className="col-xl-2 col-sm-6 col-6">
            <div className="card">
              <div className="product-box">
                <div className="product-img">
                  <img
                    className="img-fluid"
                    src={thumbnail ? thumbnail: `https://amvines.com/banner/${image}`}
                    alt=""
                    style={{height:"200px",width:'100%'}}
                    
                  />
                  <div className="product-hover">
                    <ul>
                      <li onClick={()=>addItem(props.product)}>
                        <Link to=''
                          ><i className="fa-solid fa-cart-shopping"></i></Link>
                      </li>
                      <li>
                        <Link to={`product_details/${id}`}><i className="fa-regular fa-eye"></i>
                          </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="product-details">
                  {/* <div className="rating">
                    <i className="fa fa-star"></i
                    ><i className="fa fa-star"></i
                    ><i className="fa fa-star"></i
                    ><i className="fa fa-star"></i
                    ><i className="fa fa-star"></i>
                  </div> */}
                  <Link className='text-decoration-none' to={`product_details/${id}`}>
                    <h4 title={title}>{title.length > 15 ? title.slice(0,12) + "..." : title}</h4>
                  </Link>
                  <p>{description ? description.slice(0,40) : ""}</p>
                  <div className="product-price">
                    ${sale_price ? sale_price : price}
                    <del>${price}</del>
                  </div>
                </div>
              </div>
            </div>
          </div>
       
    );
};

export default SingleProducts;