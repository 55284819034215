import React, { useContext, useEffect, useState } from "react";
import { MyContext } from "../../App";
import SingleProducts from "../SingleProduct/SingleProducts";

const Products = (props) => {

  const [isOpen, searchText, ,] = useContext(MyContext);

  const [searchedProducts, setSearchedProducts] = useState([]);

  useEffect(() => {
    fetch(`https://dummyjson.com/products/search?q=${searchText}`)
      .then((res) => res.json())
      .then((data) => setSearchedProducts(data.products));
  }, [searchText]);

  // console.log(searchedProducts,"killl");

  const scrollUp = ()=>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  }

  return (
    <>
      <div>
        {/* <!-- tap on top starts--> */}
        <div onClick={scrollUp} className="tap-top">
        <i className="fa-solid fa-angles-up"></i>
        </div>
        {/* <!-- tap on tap ends--> */}
        {/* <!-- page-wrapper Start--> */}
        <div className={`page-wrapper ${isOpen ? "compact-wrapper" : ""}`} id="pageWrapper">

          {/* <!-- Page Body Start--> */}
          <div className="page-body-wrapper">
            {/* <!-- Page Sidebar Start--> */}
            {/* <div className="sidebar-wrapper">
              <div>
                <div className="logo-wrapper">
                  <a href="index.html">
                    <img
                      style={{ height: "50px" }}
                      className="img-fluid for-light"
                      src="assets/images/logo/small-logo.png"
                      alt=""
                    />
                    <img
                      className="img-fluid for-dark"
                      src="assets/images/logo/small-white-logo.png"
                      alt=""
                    />
                  </a>
                  <div className="back-btn">
                    <i className="fa fa-angle-left"></i>
                  </div>
                </div>
                <div className="logo-icon-wrapper">
                  <a href="index.html">
                    <img
                      className="img-fluid"
                      src="assets/images/logo-icon.png"
                      alt=""
                    />
                  </a>
                </div>
                <nav className="sidebar-main">
                  <div className="left-arrow" id="left-arrow">
                    <i data-feather="arrow-left"></i>
                  </div>
                  <div id="sidebar-menu mt-5">
                    <ul className="sidebar-links" id="simple-bar">
                      <li className="back-btn">
                        <a href="index.html">
                          <img
                            className="img-fluid"
                            src="assets/images/logo-icon.png"
                            alt=""
                          />
                        </a>
                        <div className="mobile-back text-end">
                          <span>Back</span>
                          <i className="fa fa-angle-right ps-2" aria-hidden="true">
                            {" "}
                          </i>
                        </div>
                      </li>
                      <li className="mt-1" style={{ visibility: "hidden" }}>
                        cbcgbdf
                      </li>
                      <li className="sidebar-list">
                        <a className="sidebar-link sidebar-title" href="/">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g>
                              <g>
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2.75024 12C2.75024 5.063 5.06324 2.75 12.0002 2.75C18.9372 2.75 21.2502 5.063 21.2502 12C21.2502 18.937 18.9372 21.25 12.0002 21.25C5.06324 21.25 2.75024 18.937 2.75024 12Z"
                                  stroke="#130F26"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M15.2045 13.8999H15.2135"
                                  stroke="#130F26"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M12.2045 9.8999H12.2135"
                                  stroke="#130F26"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M9.19557 13.8999H9.20457"
                                  stroke="#130F26"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </g>
                            </g>
                          </svg>
                          <span className="lan-6">Widgets</span>
                        </a>
                        <ul className="sidebar-submenu">
                          <li>
                            <a href="general-widget.html">General</a>
                          </li>
                          <li>
                            <a href="chart-widget.html">Chart</a>
                          </li>
                        </ul>
                      </li>
                      <li className="sidebar-list">
                        <a className="sidebar-link sidebar-title" href="/">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g>
                              <g>
                                <path
                                  d="M15.596 15.6963H8.37598"
                                  stroke="#130F26"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M15.596 11.9365H8.37598"
                                  stroke="#130F26"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M11.1312 8.17725H8.37622"
                                  stroke="#130F26"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M3.61011 12C3.61011 18.937 5.70811 21.25 12.0011 21.25C18.2951 21.25 20.3921 18.937 20.3921 12C20.3921 5.063 18.2951 2.75 12.0011 2.75C5.70811 2.75 3.61011 5.063 3.61011 12Z"
                                  stroke="#130F26"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </g>
                            </g>
                          </svg>
                          <span className="lan-7">Page layout</span>
                        </a>
                        <ul className="sidebar-submenu">
                          <li>
                            <a href="box-layout.html">Boxed</a>
                          </li>
                          <li>
                            <a href="layout-rtl.html">RTL</a>
                          </li>
                          <li>
                            <a href="layout-dark.html">Dark Layout</a>
                          </li>
                          <li>
                            <a href="hide-on-scroll.html">Hide Nav Scroll</a>
                          </li>
                          <li>
                            <a href="footer-light.html">Footer Light</a>
                          </li>
                          <li>
                            <a href="footer-dark.html">Footer Dark</a>
                          </li>
                          <li>
                            <a href="footer-fixed.html">Footer Fixed</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <div className="sidebar-img-section">
                      <h1>
                        masterMan
                        <i className="fa-sharp fa-solid fa-circle-arrow-down"></i>
                      </h1>
                    </div>
                  </div>
                  <div className="right-arrow" id="right-arrow">
                    <i data-feather="arrow-right"></i>
                  </div>
                </nav>
              </div>
            </div> */}
            {/* <!-- Page Sidebar Ends--> */}
            <div className="page-body">
              <div className="container-fluid"></div>
              {/* <!-- Container-fluid starts--> */}
              <div className="container-fluid">
                <div className="user-profile">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 xl-65">
                      <div className="row">
                        {/* <!-- profile post start--> */}
                        <div className="col-sm-12">
                          <div className="card1 mt-2">
                            <div className="profile-post1">
                              <div className="post-body1">
                                <div className="img-container">
                                  <div className="my-gallery" itemscope="">
                                    <figure
                                      itemprop="associatedMedia"
                                      itemscope=""
                                    >
                                      <a
                                        href="assets/images/user-profile/post1.jpg"
                                        itemprop="contentUrl"
                                        data-size="1600x950"
                                      >
                                        <img
                                          style={{ height: "250px" }}
                                          className="img-fluid rounded"
                                          src="https://cdn1.arogga.com/eyJidWNrZXQiOiJhcm9nZ2EiLCJrZXkiOiJjYXRlZ29yeS1iYW5uZXJcLzBcLzE3LTFzOWQ3NGhoYjlkamEucG5nIiwiZWRpdHMiOnsicmVzaXplIjp7IndpZHRoIjoxMzcwLCJoZWlnaHQiOjIxNSwiZml0Ijoib3V0c2lkZSJ9fX0="
                                          itemprop="thumbnail"
                                          alt="gallery"
                                        />
                                      </a>
                                      <figcaption itemprop="caption description">
                                        Image caption 1
                                      </figcaption>
                                    </figure>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!-- profile post end--> */}
                      </div>
                    </div>

                    <div className="col-xl-12 col-lg-12 col-sm-12 col-md-12 xl-65">
                      <h1>Personal Care</h1>
                      <div className="product-wrapper-grid">
                        <div className="row">
                          {/* ----------Products Page start single product ----------- */}
                          {searchedProducts.map((product) => (
                            <SingleProducts
                              product={product}
                              key={product.id}
                            ></SingleProducts>
                          ))}
                        </div>
                      </div>
                    </div>

                    {/* <!-- user profile fifth-style end--> */}
                    <div
                      className="pswp"
                      tabindex="-1"
                      role="dialog"
                      aria-hidden="true"
                    >
                      <div className="pswp__bg"></div>
                      <div className="pswp__scroll-wrap">
                        <div className="pswp__container">
                          <div className="pswp__item"></div>
                          <div className="pswp__item"></div>
                          <div className="pswp__item"></div>
                        </div>
                        <div className="pswp__ui pswp__ui--hidden">
                          <div className="pswp__top-bar">
                            <div className="pswp__counter"></div>
                            <button
                              className="pswp__button pswp__button--close"
                              title="Close (Esc)"
                            ></button>
                            <button
                              className="pswp__button pswp__button--share"
                              title="Share"
                            ></button>
                            <button
                              className="pswp__button pswp__button--fs"
                              title="Toggle fullscreen"
                            ></button>
                            <button
                              className="pswp__button pswp__button--zoom"
                              title="Zoom in/out"
                            ></button>
                            <div className="pswp__preloader">
                              <div className="pswp__preloader__icn">
                                <div className="pswp__preloader__cut">
                                  <div className="pswp__preloader__donut"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                            <div className="pswp__share-tooltip"></div>
                          </div>
                          <button
                            className="pswp__button pswp__button--arrow--left"
                            title="Previous (arrow left)"
                          ></button>
                          <button
                            className="pswp__button pswp__button--arrow--right"
                            title="Next (arrow right)"
                          ></button>
                          <div className="pswp__caption">
                            <div className="pswp__caption__center"></div>
                          </div>
                        </div>
                        {/* <!-- Container-fluid Ends--> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
