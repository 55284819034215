import React from 'react';
// import Products from '../Prouducts/Products';
import HomePageProducts from './HomePageProducts';
import HomePageSlider from './HomePageSlider';

const HomePage = (props) => {

  return (
    <div>
      <HomePageSlider/>
      {/* <Products/> */}
      <HomePageProducts/>

    </div>
  );
};

export default HomePage;