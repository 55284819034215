// import './App.css';
import { Routes, Route } from "react-router-dom";

// import PageBody from "./components/PageBody/PageBody";
import PageFooter from "./components/PageFooter/PageFooter";
import PageHeader from "./components/PageHeader/PageHeader";
// import PageSideBar from "./components/PageSideBar/PageSideBar";
import Products from "./components/Prouducts/Products";
import "bootstrap/dist/css/bootstrap.min.css";
import ProductDetails from "./components/ProductDetails/ProductDetails";
import { createContext, useState } from "react";
import HomePage from "./components/HomePage/HomePage";
import PageSideBar from "./components/PageSideBar/PageSideBar";

// react use cart to add to cart
import { CartProvider } from "react-use-cart";
import CategoryProducts from "./components/CategoryProducts/CategoryProducts";
import PageNotFound from "./components/PageNotFound/PageNotFound";

// Using Context Api and deal with props
export const MyContext = createContext();

function App() {
  // const [addedCart, setAddedCart] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isOpen, setIsOpen] = useState(true);

  // handleOnChange search by input field value
  const handleOnChange = (e) => {
    setSearchText(e.target.value);
    // console.log(e.target.value);
  };

  const toggleSideBar = () => {
    setIsOpen(!isOpen);
    // console.log("helloo toggleSideBar",isOpen);
  };

  return (
    <CartProvider>
      <MyContext.Provider
        value={[
          isOpen,
          searchText,
          handleOnChange,
          toggleSideBar
        ]}
      >
        <div>
          <PageHeader />
          <PageSideBar />
          {/* <HomePage/> */}
          {/* <Products/> */}
          {/* <PageBody /> */}

          <Routes>
            <Route index element={<HomePage />} />

            <Route path="/products" element={<Products />} />
            <Route path="/category" element={<CategoryProducts />} />

            <Route
              path="product_details/:productId"
              element={<ProductDetails />}
            />
            <Route path="*" element={<PageNotFound />} />

          </Routes>

          <PageFooter />
        </div>
      </MyContext.Provider>
    </CartProvider>
  );
}

export default App;
